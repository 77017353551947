

.app_wrapper{
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(3, 100%);
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/*@media (max-width: 1024px) {*/
/*    .app_wrapper{*/
/*        scroll-snap-type: both mandatory;*/
/*    }*/
/*}*/

.app_wrapper::-webkit-scrollbar {
    width: 0;
    height: 0;
}
