.right_panel {
    float: left;
    width: calc(100% - 321px);
    height: inherit;
    background:  rgb(23,33,43);
}

@media screen and (max-width: 720px) {
    .right_panel {
        width: calc(100% - 81px);
    }
}

@media screen and (max-width: 480px) {
    .right_panel{
        width: 100%;
    }
}