@mixin x_arrows($px) {
    width: $px;
    height: 100vh;
}

@mixin y_arrows($px){
    height: $px;
    width: 100%;
}

.arrow_base {
    position: fixed;
    cursor: pointer;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;

    display: flex;
    align-items: center;
    justify-content: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.left_arrow{
    @include x_arrows(150px);
    top: 0;
    left: 0;

    border-right: 1px solid rgba(255,255,255,0.1);
    transition-duration: 300ms;
    transition-property: background-color, border-right;
}

.left_arrow:hover{
    border-right: 1px solid rgba(255,255,255,0.2);
    background: rgba(255, 255, 255, 0.01);
}

.right_arrow{
    @include x_arrows(150px);
    top: 0;
    right: 0;

    border-left: 1px solid rgba(255,255,255,0.1);
    transition-duration: 300ms;
    transition-property: background-color, border-left;
}

.right_arrow:hover{
    border-left: 1px solid rgba(255,255,255,0.2);
    background: rgba(255, 255, 255, 0.01);
}

.up_arrow{
    @include y_arrows(70px);
    top: 0;
    left: 0;

    border-bottom: 1px solid rgba(255,255,255,0.1);
    transition-duration: 300ms;
    transition-property: background-color, border-bottom;
}

.up_arrow:hover{
    border-bottom: 1px solid rgba(255,255,255,0.2);
    background: rgba(255, 255, 255, 0.01);
}

.down_arrow{
    @include y_arrows(100px);
    bottom: 0;
    left: 0;

    border-top: 1px solid rgba(255,255,255,0.1);
    transition-duration: 300ms;
    transition-property: background-color, border-top;
}

.down_arrow:hover{
    border-top: 1px solid rgba(255,255,255,0.2);
    background: rgba(255, 255, 255, 0.01);
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.arrow_img{
    width: 96px;
    height: 96px;
}

@media screen and (max-width:820px) {
    .left_arrow {
        @include x_arrows(100px);
    }
    .right_arrow {
        @include x_arrows(100px);
    }
    //.up_arrow{
    //    @include y_arrows(80px)
    //}
    .down_arrow{
        @include y_arrows(60px)
    }

    .arrow_img{
        width: 72px;
        height: 72px;
    }
}

@media screen and (max-width:540px) {
    .left_arrow {
        @include x_arrows(50px);
    }
    .right_arrow {
        @include x_arrows(50px);
    }
    //.up_arrow{
    //    @include y_arrows(40px)
    //}
    .down_arrow{
        @include y_arrows(60px)
    }

    .arrow_img{
        width: 64px;
        height: 64px;
    }
}

@media screen and (max-width:380px) {
    .left_arrow {
        @include x_arrows(40px);
    }
    .right_arrow {
        @include x_arrows(40px);
    }
    //.up_arrow{
    //    @include y_arrows(40px)
    //}
    //.down_arrow{
    //    @include y_arrows(40px)
    //}
}