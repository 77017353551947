.header {
  width: 100%;
  height: 70px;
  background: var(--telegram-background);
}

.menu {
  float: left;
  width: 32px;
  height: 32px;
  margin: 20px 15px 0;
  color: #BBB;
  cursor: pointer;
  opacity: 0.6;
  background: none;
}

.menu:hover {
  opacity: 1;
}

.menu > svg {
  width: 24px;
  height: 24px;
  fill: rgb(96,116,132);
}

.search_chats {
  width: calc(100% - 85px);
  height: 40px;
  color: rgb(127,145,164);
  background: rgb(36,47,61);
  float: left;
  padding: 8px;
  margin-top: 14px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}

.search_chats:focus {
  border: 2px solid rgba(43,82,120);
}

@media screen and (max-width: 720px) {
  .search_chats {
    display: none;
  }
}

