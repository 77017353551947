.top_bar {
    position: relative;
    width: 100%;
    height: 70px;
    background:  rgb(23,33,43);
}

.left_side {
    clear: none;
    float: left;
}

.chat_name {
    float: left;
    margin-left: 70px;
    margin-top: 13px;
    color: #FFF;
    font-weight: 600;
    cursor: default;
}

.image {
    position: absolute;
    width: 50px;
    height: 50px;
    background-size: cover;
    border-radius: 100%;
    margin: 10px 10px;
}

.chat_name > span {
    font-size: 12px;
    font-weight: 500;
    color: #BBB;
    margin-left: 10px;
}

.chat_status {
    float: left;
    clear: left;
    margin-left: 70px;
    margin-top: 5px;
    color: rgb(67,157,213);
    font-weight: 300;
    cursor: default;
}

.right_side {
    clear: none;
    float: right;
    margin-right: 20px;
}

.tb_button  {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background: var(--telegram-background);
    color: rgb(96,116,132);
    border-radius: 100%;
}

.tb_button:hover {
    color: #fff;
}