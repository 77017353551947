.screen_hoc{
    width: 100%;
    height: 100vh;
    //scroll-snap-align: start;
    //scroll-snap-stop: always;
    display: flex;
    justify-content: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//@media (max-width: 1024px) {
//    .screen_hoc{
//        scroll-snap-align: start;
//        scroll-snap-stop: always;
//    }
//}


.screen_wrapper{
    height: 100vh;
    width: 100%;
    max-width: 1920px;
    display: flex;
}

.container{
    width: inherit;
    padding-left: 180px;
    padding-right: 180px;
    padding-top: 300px;
}

.content_wrapper{
    padding-top: 40px;
}

.content_title{
    font-weight: 600;
    font-size: 3em;
    text-transform: uppercase;
}

@media screen and (max-width: 525px) {
    .content_title{
        font-size: 2.5em;
    }
}

.content_list{
    padding-top: 20px;
}

.content_list > li{
    list-style-type: none;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 1.5em;
    font-size: 1.2em;
    letter-spacing: 0.15em;
}

@media screen and (max-width:820px) {
    .container{
        width: inherit;
        padding-left: 130px;
        padding-right: 130px;
    }

    .content_title{
        font-size: 2.5em;
    }

    .content_wrapper > ul > li{
        font-size: 1.2em;
        letter-spacing: 0.1em;
    }
}

@media screen and (max-width:660px) {
    .content_title{
        font-size: 1.8em;
    }

    .content_wrapper > ul > li{
        font-size: 1em;
        letter-spacing: 0.1em;
    }
}

@media screen and (max-width:540px) {
    .container{
        width: inherit;
        padding-left: 80px;
        padding-right: 80px;
    }
}

@media screen and (max-width:484px) {
    .container{
        width: inherit;
        padding-left: 60px;
        padding-right: 60px;
    }
    .content_title{
        font-size: 2em;
    }

    .content_wrapper > ul > li{
        font-size: 1.1em;
        letter-spacing: 0;
    }
}

@media screen and (max-width:440px) {

    .content_title{
        font-size: 1.6em;
    }

    .content_wrapper > ul > li{
        font-size: 1em;
        letter-spacing: 0;
    }
}

@media screen and (max-height:640px) {
    .container{
        padding-top: 250px;
    }
}

@media screen and (max-height:540px) {
    .container{
        padding-top: 160px;
    }
}

@media screen and (max-height:440px) {
    .container{
        padding-top: 120px;
    }

    .content_title{
        font-size: 2.5em;
    }

    .content_list{
        padding-top: 10px;
    }

    .content_list > li{
        line-height: 1.1em;
        font-size: 1em;
        letter-spacing: 0.15em;
    }
}
