.chats {
  width: 100%;
  height: calc(100vh - 70px);
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}

.chat_button {
  float: left;
  width: 100%;
  height: 80px;
  background: var(--telegram-background);
  color: rgb(127,145,164);
  cursor: pointer;
  overflow: hidden;
  font-size: 14px;
}

.chat_button:hover {
  background: rgb(32,43,54);
}

.active, .active:hover {
  color: #FFF;
  background: rgba(43,82,120);
}


.chat_info {
  float: left;
}

.chat_info p {
  float: left;
}

.image {
  position: absolute;
  width: 55px;
  height: 55px;
  background-size: cover;
  border-radius: 100%;
  margin: 13px 13px;
}

.name {
  float: left;
  font-weight: 600;
  margin-left: 80px;
  margin-top: 18px;
  color: #FFF;
}

.message {
  float: left;
  clear: left;
  margin-left: 80px;
  margin-top: 7px;
}

.status {
  width: 60px;
  height: 100%;
  position: relative;
  float: right;
}

.fixed {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: block;
  width: 24px;
  height: 24px;
  fill: #FFF;
}

.count {
  position: absolute;
  right: 10px;
  bottom: 15px;
  width: auto;
  height: auto;
  padding: 6px;
  border-radius: 20px;
  color: #ffffff;
  background: rgba(62, 84, 106, 1);
  font-size: 12px;
}

.date {
  position: absolute;
  right: 12px;
  top: 12px;
  font-size: 12px;
  color: rgb(127,145,164);
}

.read {
  position: absolute;
  right: 53px;
  top: 6px;
  color: rgb(113, 187, 252);
}


@media screen and (max-width: 720px) {
  .left_panel {
    width: 80px;
  }


   .name,
  .message,
   .status {
    display: none;
  }
}
