.game_field{
  background-color: blanchedalmond;
  /*width: 100%;*/
  /*height: 100vh;*/
  /*height: 100vh;*/
  /*width: 100vw;*/
  /*display: block;*/
  overflow: hidden;
}

.player{
  width: 50px;
  height: 50px;
  background-color: black;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%,0);
  transition: all 2s ease-in-out;
}


/*@media screen and (min-width: 480px) {*/
/*  .game_field {*/
/*    width: 80%;*/
/*    height: 80vh;*/
/*  }*/
/*}*/