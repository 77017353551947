.chat_bg {
    float: left;
    position: relative;
    width: 100%;
    background-color: rgb(14,22,33);
    overflow-x: hidden;
    overflow-y: hidden;
}

.message {
    position: relative;
    width: auto;
    min-width: 100px;
    max-width: 45%;
    height: auto;
    padding: 20px 20px 30px;
    margin: 15px 15px 0;
    border-radius: 7px;
    clear: both;

    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;
    animation-duration: 0.15s;
}

.message:nth-last-child(1) {
    margin-bottom: 20px;
}

.timestamp {
    display: block;
    position: absolute;
    right: 10px;
    bottom: 6px;
    color: #fff;
    font-size: 14px;
    user-select: none;
}

.received {
    float: left;
    background: rgb(42,48,56);
}

.sent {
    float: right;
    background: rgb(40,122,154);
}

.sent > .timestamp, .sent > .read_status {
    bottom: 10px;
    right: 40px;
    color: #fff;
    user-select: none;
}

.sent > .read_status {
    position: absolute;
    font-size: 20px;
    bottom: 10px;
    right: 12px;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 940px) {
    /*body {*/
    /*    font-size: 14px;*/
    /*}*/

    .message {
        width: 90%;
    }
}


@media screen and (max-width: 720px) {
    .message {
        width: auto;
        max-width: 60%;
    }
}