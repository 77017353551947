body {
  margin: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*overflow: hidden;*/
  font-size: 16px;
  font-weight: 400;
  overflow: hidden;
  color: white;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

* {
  margin: 0;
  padding: 0;
}

button, input[type="search"], input[type="text"] {
  border: none;
  outline: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@font-face {
  font-family: PressStart2P;
  src: url(./media/fonts/PressStart2P-Regular.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-Thin.ttf);
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: Montserrat;
  src: url(./media/fonts/Montserrat-Black.ttf);
  font-weight: 900;
  font-style: normal;
}



