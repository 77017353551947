.container{
  width: calc(100vw - 180px * 2);
  position: absolute;
  left: 180px;
  top: 30px;
}

.logo{
  font-size: 5em;
  line-height: 1em;
  font-weight: 700;
}

.email{
  font-size: 1.2em;
  text-decoration: underline;
}

@media screen and (max-width:820px) {
  .email {
    position: fixed;
    bottom: 100px;
  }
}


.email > a:active,
.email >a {
  color: #ffffff;
}

.email >a:hover{
  color: #ababab;
}

.header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_content{
  padding-top: 10px;
  display: flex;
}

@media screen and (max-width:720px) {
  .header_content{
    flex-direction: column;
  }
}


.header_content > span {
  margin-right: 10px;
  color: rgba(255,255,255,0.7);
}

.header_active{
  border-bottom: 1px solid white
}

.counter_wrapper{
  padding-top: 120px;
  display: flex;
  align-items: flex-start;
}

.counter_current{
  font-size: 2em;
  line-height: 0.85em;
}

.counter_all{
  font-size: 1em;
  line-height: 1em;
  font-weight: 300;
  color: rgba(255,255,255,0.7);
}

.video{
  position: fixed;
  top:0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -999;
}

.background{
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
  background-image: url("../media/background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

}

.blackCover{
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  z-index: -998;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (max-width:820px) {
  .container{
    left: 130px;
    width: calc(100vw - 130px * 2);
  }
}

@media screen and (max-width:660px) {

}

@media screen and (max-width:540px) {
  .container{
    left: 80px;
    width: calc(100vw - 80px * 2);
  }
}

@media screen and (max-width:484px) {
  .container{
    left: 60px;
    width: calc(100vw - 60px * 2);
  }

  .logo{
    font-size: 4em;
  }
}

@media screen and (max-width:440px) {

}

@media screen and (max-height:640px) {
  .counter_wrapper{
    padding-top: 80px;
  }
}

@media screen and (max-height:540px) {
  .counter_wrapper{
    padding-top: 10px;
  }
}

@media screen and (max-height:440px) {
  .logo{
    font-size: 3em;
  }
}
