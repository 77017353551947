.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding: 70px 70px 30px 70px;
    gap: 30px 70px;
    width: 100vw;
    height: calc(100vh - 70px - 30px);
}

@media screen and (max-width:1200px) {
    .wrapper{
        gap: 30px 30px;
        padding-left: 50px;
        padding-right: 50px;
    }
}


@media screen and (max-width:900px) {
    .wrapper{
        gap: 20px 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width:820px) {
    .wrapper{
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:500px) {
    .wrapper{
        grid-template-columns: 1fr;
    }
}