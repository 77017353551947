.wrapper{
    --telegram-background: rgb(23,33,43);
    width: 100vw;
    height: inherit;
}

.userBg {
    /*background: url("https://images.unsplash.com/photo-1463058837219-41e13a132b7d?dpr=1&auto=format&fit=crop&w=1500&h=1000&q=80&cs=tinysrgb&crop=&bg=");*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
}



@media screen and (max-width: 940px) {

}

@media screen and (max-width: 720px) {

}

@media screen and (max-width: 480px) {

}