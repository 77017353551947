.typing_wrapper{
    float: left;
    clear: left;
    margin-left: 70px;
    margin-top: 5px;
    color: rgb(67,157,213);
    font-weight: 300;
    cursor: default;
    display: flex;
    align-items: center;
}

.typing_text{
    margin-left: 15px;
}

.dot_flashing {
    margin-top: 2px;
    margin-left: 10px;
    position: relative;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(67,157,213);
    color: rgb(67,157,213);
    animation: dot_flashing 0.6s infinite ease-in-out alternate;
    animation-delay: .3s;
}

.dot_flashing::before, .dot_flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot_flashing::before {
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(67,157,213);
    color: rgb(67,157,213);
    animation: dot_flashing 0.6s infinite ease-in-out alternate;
    animation-delay: 0s;
}

.dot_flashing::after {
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: rgb(67,157,213);
    color: rgb(67,157,213);
    animation: dot_flashing 0.6s infinite ease-in-out alternate;
    animation-delay: 0.6s;
}

@keyframes dot_flashing {
    0% {
        background-color: rgb(67,157,213);;
    }
    50%,
    100% {
        background-color: #ebe6ff;
    }
}