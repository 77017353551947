.reply_bar {
    width: 100%;
    height: 70px;
    float: left;
    background: var(--telegram-background);
}

.attach {
    width: 70px;
    height: 70px;
    color: rgb(96,116,132);
    background: var(--telegram-background);
    float: left;
}

.attach:hover {
    color: #fff;
}

.d45 {
    transform: rotate(45deg);
    font-size: 32px;
}

.reply_message {
    width: calc(100% - 220px);
    color: rgb(127,145,164);
    background: var(--telegram-background);
    float: left;
    height: 70px;
    padding: 0 8px;
    font-size: 15px;
}

.reply_message:focus{
    color: #fff;
}

.other_tools {
    float: right;
    width: 120px;
    height: 70px;
}

.tool_buttons {
    width: 50px;
    height: 50px;
    margin-top: 10px;
    background: var(--telegram-background);
    color: rgb(96,116,132);
    border-radius: 100%;
}

.tool_buttons:hover {
    color: #fff;
}
