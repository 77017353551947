.left_panel {
    position: relative;
    display: inline-block;
    left: 0;
    width: 320px;
    height: inherit;
    float: left;
    background: var(--telegram-background);
    border-right: 1px solid rgb(16,25,33);
}

@media screen and (max-width: 940px) {

}

@media screen and (max-width: 720px) {
    .left_panel {
        width: 80px;
    }


    .search_chats,
    .chat_button > .name,
    .chat_button > .message,
    .chat_button > .status {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .left_panel {
        display: none;
    }
}